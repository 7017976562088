import "./Hero.css";

export default function Hero() {
  return (
    <div id="hero">
      <div className="container hero-content">
        <h1>
          <span className="hero-header">Hello! 🙂</span>{" "}
          <span className="hero-paragraph">
            I'm <span className="highlight">Alan</span>, a full-stack{" "}
            <span className="developer">developer</span>,
            <span className="runner"> runner</span>,{" "}
            <span className="golfer">golfer</span> &{" "}
            <span className="lover">music </span>lover.
          </span>
        </h1>
      </div>
    </div>
  );
}
