import "./About.css";
import icons from "./iconData";

export default function About() {
  return (
    <section className="container">
      <div className="stack">
        <div className="banner">
          <ul>
            {icons.map((icon, index) => (
              <li key={index}>
                <img
                  src={icon.src}
                  alt={`${icon.text} logo.`}
                  title={icon.text}
                  className="icon"
                />
              </li>
            ))}
          </ul>
          <ul aria-hidden="true">
            {icons.map((icon, index) => (
              <li key={index}>
                <img
                  src={icon.src}
                  alt={`${icon.text} logo.`}
                  title={icon.text}
                  className="icon"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="about-wrapper">
        <div id="about" className="about-text">
          <h3>
            Hello again <span className="reverse-icon">👋</span>
          </h3>
          <p>
            I'm Alan, a passionate and creative full-stack developer, currently
            focused on building my skills through a comprehensive full-stack
            development course at Lexicon in Malmö. While I enjoy working on
            both the front and back end of web applications, I'm especially
            drawn to front-end development, where I can bring designs to life.
          </p>

          <p>
            Throughout my journey, I have worked on various personal and group
            projects, which have strengthened my problem-solving abilities and
            allowed me to collaborate effectively with others.
          </p>

          <p>
            My goal is to get my foot in the door and begin my career in the
            tech industry, this portfolio is a reflection of my dedication and
            the skills I've developed so far.
          </p>
        </div>
      </div>
    </section>
  );
}
