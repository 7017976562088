import React from "react";
import "./Projects.css";
import ProjectData from "./ProjectData";
import icons from "../about/iconData";

export default function Projects() {
  const renderIcons = (stack) => {
    const matchingIcons = icons.filter((icon) => stack.includes(icon.text));

    return (
      <div className="icon-container">
        {matchingIcons.map((icon, idx) => (
          <img
            key={idx}
            src={icon.src}
            alt={icon.text}
            className={`icon ${icon.iconClass}`}
            title={icon.text}
          />
        ))}
      </div>
    );
  };

  return (
    <div id="projects" className="container">
      {ProjectData.map((project, index) => {
        const pushClass = index % 2 === 0 ? "left-push" : "right-push";

        return (
          <div key={`Project ${index}`} className={`card ${pushClass}`}>
            <img src={project.img} alt={project.title} />
            <div className="card-header">
              <h2>{project.title}</h2>
              {renderIcons(project.stack)}
            </div>

            <p>{project.description}</p>
            <div>
              {project.liveUrl && (
                <a
                  href={project.liveUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live Demo
                </a>
              )}
              <a
                href={project.githubUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Code
              </a>
            </div>
            <div className="dot-wrapper">
              <div className="dot dot-blue"></div>
              <div className="dot dot-mid"></div>
              <div className="dot dot-green"></div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
