import bootstrap from "../../assets/svg/bootstrap.svg";
import cSharp from "../../assets/svg/cSharp.svg";
import css from "../../assets/svg/css.svg";
import dotNetCore from "../../assets/svg/dot-net-core.svg";
import fastapi from "../../assets/svg/fastapi.svg";
import flask from "../../assets/svg/flask.svg";
import html from "../../assets/svg/html.svg";
import javascript from "../../assets/svg/javascript.svg";
import python from "../../assets/svg/python.svg";
import react from "../../assets/svg/react.svg";
import tailwindcss from "../../assets/svg/tailwind-css.svg";
import vite from "../../assets/svg/vite.svg";
import nextjs from "../../assets/svg/nextjs.svg";
import expressjs from "../../assets/svg/expressjs.svg";
import nodejs from "../../assets/svg/nodejs.svg";
import mongodb from "../../assets/svg/mongodb.svg";
import photoshop from "../../assets/svg/photoshop.svg";
import figma from "../../assets/svg/figma.svg";
import typescript from "../../assets/svg/typescript.svg";

const icons = [
  { src: html, text: "HTML", iconClass: "html", category: "frontend" },
  { src: css, text: "CSS", iconClass: "css", category: "frontend" },
  {
    src: tailwindcss,
    text: "Tailwind",
    iconClass: "tailwind",
    category: "frontend",
  },
  {
    src: bootstrap,
    text: "Bootstrap",
    iconClass: "bootstrap",
    category: "frontend",
  },
  {
    src: javascript,
    text: "JavaScript",
    iconClass: "js",
    category: "frontend",
  },
  {
    src: typescript,
    text: "TypeScript",
    iconClass: "ts",
    category: "frontend",
  },
  { src: react, text: "React", iconClass: "react", category: "frontend" },
  {
    src: nextjs,
    text: "Next.js",
    iconClass: "nextjs",
    category: "frontend",
  },
  { src: vite, text: "Vite", iconClass: "vite", category: "frontend" },
  { src: nodejs, text: "Node.js", iconClass: "node", category: "backend" },
  {
    src: expressjs,
    text: "Express.js",
    iconClass: "express",
    category: "backend",
  },
  {
    src: mongodb,
    text: "MongoDB",
    iconClass: "mongodb",
    category: "backend",
  },
  { src: figma, text: "Figma", iconClass: "figma", category: "design" },
  {
    src: photoshop,
    text: "Photoshop",
    iconClass: "photoshop",
    category: "design",
  },
  {
    src: python,
    text: "Python",
    iconClass: "python",
    category: "backend",
  },
  {
    src: fastapi,
    text: "FastAPI",
    iconClass: "fastapi",
    category: "backend",
  },
  { src: flask, text: "Flask", iconClass: "flask", category: "backend" },
  { src: cSharp, text: "C#", iconClass: "dotnet", category: "backend" },
  {
    src: dotNetCore,
    text: ".NET",
    iconClass: "dotnet",
    category: "backend",
  },
];

export default icons;
