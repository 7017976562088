const ProjectData = [
  {
    title: "Prism",
    description:
      "Prism is a mock website designed for a fictional shoe brand, featuring a modern and user-friendly interface that presents key information about the brand while enabling users to pre-order shoes. Developed using HTML, CSS, and JavaScript, this project showcases my ability to create engaging and functional web experiences. The design emphasizes a clean layout and intuitive navigation, making it easy for users to explore the brand's offerings.",
    stack: ["HTML", "CSS", "JavaScript", "nextjs"],
    liveUrl: "https://prism.alansweeney.se/",
    githubUrl: "https://github.com/al-swe/prism-portfolio",
    img: "https://www.dropbox.com/scl/fi/i4261up9hlq2qggpobelc/prism.webp?rlkey=u917k59nnk11co3op3n3i4oky&st=45oidx5w&raw=1",
  },
  {
    title: "CoinMeter",
    description:
      "CoinMeter is a dynamic web application that allows users to view real-time updates on the 10 most popular cryptocurrencies, with the data refreshing every 10 seconds. Designed to experiment with REST APIs, this project integrates seamlessly with the CoinCap API to provide accurate and timely cryptocurrency information. Built using HTML, CSS, and JavaScript, CoinMeter showcases my ability to work with external data sources and create interactive web applications. This project serves as a demonstration of my technical skills and passion for exploring emerging technologies in the cryptocurrency space.",
    stack: ["HTML", "CSS", "JavaScript"],
    liveUrl: "https://coinmeter.alansweeney.se/",
    githubUrl: "https://github.com/al-swe/coinmeter-portfolio",
    img: "https://www.dropbox.com/scl/fi/jb5otitmeqzmg0gt39se9/coinmeter.webp?rlkey=2cd3oevnhgucb09p6uyvw92hw&st=qffr1j1x&raw=1",
  },
  {
    title: "Stellar",
    description:
      "Stellar is a mock website for a fictional car company that effectively showcases its diverse range of vehicles through a visually stunning and immersive design. Utilizing HTML, CSS, Bootstrap, and JavaScript, this project features a full-page scroll experience that allows users to explore the brand's offerings seamlessly. The integration of AI-generated images from Midjourney enhances the visual appeal, presenting the cars in a captivating manner. This project serves as a testament to my skills in creating responsive and engaging web applications while demonstrating my ability to leverage cutting-edge technology in design.",
    stack: ["HTML", "CSS", "Bootstrap", "JavaScript"],
    liveUrl: "https://stellar.alansweeney.se/",
    githubUrl: "https://github.com/al-swe/stellar-portfolio",
    img: "https://www.dropbox.com/scl/fi/30kjxi96gq4izqxc8jekd/stellar.webp?rlkey=taqdg5edgzu4pr2an30x0tpt1&st=0c49uyur&raw=1",
  },
  {
    title: "Pokémon Battle",
    description:
      "Pokémon Battle is an interactive web application that allows users to select two Pokémon and pit them against each other in a virtual battle. This project serves as an engaging way to test and explore REST APIs, utilizing data from the PokéAPI. Built with HTML, CSS, and JavaScript, the application emphasizes a user-friendly interface. Through this project, I demonstrated my ability to integrate external APIs and create dynamic, engaging web applications, showcasing my skills in both frontend development and API utilization.",
    stack: ["HTML", "CSS", "JavaScript"],
    liveUrl: "https://pkmnbtl.alansweeney.se",
    githubUrl: "https://github.com/al-swe/pokemon-battle-portfolio",
    img: "https://www.dropbox.com/scl/fi/7ravcnp3qvxz2kzexilq8/pokemon.webp?rlkey=y3tvf8dgtv8kp1zczvx8mm46g&st=mx84kvcp&raw=1",
  },
  {
    title: "Cocktail API",
    description:
      "Cocktail API is a backend application built with Python and FastAPI that allows users to access a variety of cocktail recipes. This project serves as a practical experiment to test the capabilities of FastAPI, providing a seamless interface for users to tap into the API and retrieve recipe data. The API features comprehensive documentation through Swagger and ReDoc, making it easy for developers to understand and utilize the available endpoints. Future plans include developing a front-end website that enhances user interaction with the API and showcases customized cocktail recipes that I have created or modified, expanding on the classics.",
    stack: ["Python", "FastAPI"],
    liveUrl: "https://cocktail-api-fastapi.vercel.app/",
    githubUrl: "https://github.com/al-swe/cocktail-api-fastapi-portfolio",
    img: "https://www.dropbox.com/scl/fi/b3o0q1jqxrzctzexx9dw5/cocktail-api.webp?rlkey=ll85g7dzv7ne6hgy2nrhf8yqc&st=123vfez0&raw=1",
  },
  {
    title: "Shopping List",
    description:
      "This is a full-stack application that combines a Python FastAPI backend with a responsive HTML, CSS, and JavaScript frontend. This app allows users to easily add items to their shopping list, with all data being securely saved on the backend. The project serves as a practical exercise in building a complete web application, showcasing my ability to integrate both frontend and backend technologies effectively. This project highlights my skills in full-stack development and my commitment to creating practical solutions for everyday tasks.",
    stack: ["Python", "FastAPI", "HTML", "CSS", "JavaScript"],
    liveUrl: "https://shopping-list.alansweeney.se",
    githubUrl: "https://github.com/al-swe/shopping-list-portfolio",
    img: "https://www.dropbox.com/scl/fi/fqu7rfhfwalh3ahl55zvk/shopping-list.png?rlkey=v5a7dqsid01rzci84s6x5ig6c&st=wgtwfnsc&raw=1",
  },
  {
    title: "InnFinder",
    description:
      "InnFinder is a comprehensive full-stack application developed as a final group project for my current course. The app features a modern frontend built with HTML, CSS, JavaScript, and React (using Vite), while the backend utilizes .NET Core to create an API that seamlessly connects the database with the frontend. InnFinder allows users to search for hotels based on destination and dates, enhancing the user experience with a clean and intuitive interface. Additionally, the application includes a login feature that restricts access to the dashboard, where administrators can perform CRUD (Create, Read, Update, Delete) operations on rooms, bookings, and hotels. This project showcases my ability to work collaboratively in a team environment while integrating multiple technologies to create a functional and user-friendly application.",
    stack: ["C#", ".NET", "HTML", "CSS", "Vite"],
    liveUrl: "",
    githubUrl: "https://github.com/theodor90/hotel-booking",
    img: "https://www.dropbox.com/scl/fi/1amv1fgque6msumjghvgv/InnFinder.webp?rlkey=oemy6foqm07ykzciwjpf4xuif&st=ed3ogac2&raw=1",
  },
  {
    title: "Password Generator",
    description:
      "A full-stack application that lets users create secure passwords tailored to their preferences. Users can specify the desired number of characters for their password, and the app generates a strong, random password accordingly. Built with HTML, CSS, and React for the frontend, and powered by Python and FastAPI for the backend, this project demonstrates my ability to create a seamless user experience. The application’s intuitive interface allows users to easily navigate the password generation process, showcasing my skills in both frontend and backend development.",
    stack: ["Python", "FastAPI", "HTML", "CSS", "React"],
    liveUrl: "https://pswrd-gnrtr.alansweeney.se/",
    githubUrl: "https://github.com/al-swe/pw-gnrtr-portfolio",
    img: "https://www.dropbox.com/scl/fi/jgbd3wkuu7ijya29c7npm/pswrd-gnrtr.webp?rlkey=0ttadatfwlhi2911tg1qd8uo4&st=acta58c6&raw=1",
  },
];

export default ProjectData;
