import "./Footer.css";

export default function Footer() {
  return (
    <footer>
      <a href="https://github.com/al-swe" target="_blank" rel="noreferrer">
        GitHub
      </a>
      <a
        href="https://www.linkedin.com/in/alan-sweeney-84890624b/"
        target="_blank"
        rel="noreferrer"
      >
        LinkedIn
      </a>
    </footer>
  );
}
