import "./App.css";
import Nav from "./components/nav/Nav";
import Hero from "./containers/hero/Hero";
import Projects from "./containers/projects/Projects";
import Footer from "./containers/footer/Footer";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useGSAP } from "@gsap/react";
import About from "./containers/about/About";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(useGSAP);

function App() {
  return (
    <div>
      <Nav />
      <Hero />
      <Projects />
      <About />
      <Footer />
    </div>
  );
}

export default App;
