import React, { useEffect, useState } from "react";
import "./Nav.css";
import Logo from "../../assets/favicon.png";

export default function Nav() {
  const [scrolled, setScrolled] = useState(false);
  const [showHome, setShowHome] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

      if (window.scrollY > window.innerHeight) {
        setShowHome(true);
      } else {
        setShowHome(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="nav" className={scrolled ? "scrolled" : ""}>
      <img src={Logo} alt="Alan Sweeney Logo" />
      <ul>
        {showHome && (
          <li>
            <a href="#hero">Back to top</a>
          </li>
        )}
        <li>
          <a href="#projects">Projects</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
      </ul>
    </div>
  );
}
